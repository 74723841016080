"use client";

import { AuthContext } from "@/Providers/FirebaseProvider";
import { useContext } from "react";

const useAuth = () => {
  const Auth = useContext(AuthContext);

  return Auth;
};

export default useAuth;
